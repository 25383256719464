import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import global from '../../../classes/global';
import {
    NavLink,
    HashRouter
} from "react-router-dom";
import Button from '@material-ui/core/Button';

export default class AdminHomePage extends React.Component<any, any> {

    private rootWeb: any;
    public currentUser: any;

    constructor(props: any) {
        super(props);

        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        if (!this.props.Department) {
            window.open('#/AccessRequest', '_self');
        };
    }
    public componentDidMount() {
        localStorage.removeItem('datagridFilterValue');
        localStorage.removeItem('datagridSearchPanelValue');
    }
    public render(): React.ReactElement<any> {
        return (
            <div className={'dashboardHome'}>

                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <div className={"adminBoxDiv"}>
                                <NavLink to={'/AdminListBuilder'} >
                                    <Button variant='outlined' color="primary" className={'button'}>
                                        Go to Admin List Builder
                                    </Button>
                                </NavLink>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <p className="heading ml-10">User Management</p>
                                        <div className={"adminPaperDiv"}>
                                            <NavLink exact to="/Employees">
                                                <Paper className={"adminPaper"}>User</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Crews">
                                                <Paper className={"adminPaper"}>Crew</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Teams">
                                                <Paper className={"adminPaper"}>Teams</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Departments">
                                                <Paper className={"adminPaper"}>Department</Paper>
                                            </NavLink>
                                            {/* <NavLink exact to="/Roles">
                                                <Paper className={"adminPaper"}>Roles</Paper>
                                            </NavLink> */}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <p className="heading ml-10">Form Management</p>
                                        <div className={"adminPaperDiv"}>
                                            <NavLink exact to="/Activities">
                                                <Paper className={"adminPaper"}>Fields</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Fields">
                                                <Paper className={"adminPaper"}>Form Types</Paper>
                                            </NavLink>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <p className="heading ml-10">Work Management</p>
                                        <div className={"adminPaperDiv"}>
                                            <NavLink exact to="/Activities">
                                                <Paper className={"adminPaper"}>Activities</Paper>
                                            </NavLink>
                                            <NavLink exact to="/WorkTypeField">
                                                <Paper className={"adminPaper"}>Activity Fields</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Plants">
                                                <Paper className={"adminPaper"}>Equipment and Vehicles</Paper>
                                            </NavLink>
                                            <NavLink exact to="/PlantCategory">
                                                <Paper className={"adminPaper"}>Plant Category</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Tcp">
                                                <Paper className={"adminPaper"}>TCP</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Swms">
                                                <Paper className={"adminPaper"}>SWMS</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Forms">
                                                <Paper className={"adminPaper"}>Forms</Paper>
                                            </NavLink>
                                            <NavLink exact to="/PrestartForms">
                                                <Paper className={"adminPaper"}>Prestart Forms</Paper>
                                            </NavLink>
                                            <NavLink exact to="/DefectCode">
                                                <Paper className={"adminPaper"}>Defect Codes</Paper>
                                            </NavLink>
                                            <NavLink exact to="/DefectQuestion">
                                                <Paper className={"adminPaper"}>Defect Questions</Paper>
                                            </NavLink>
                                            <NavLink exact to="/AssetQuestion">
                                                <Paper className={"adminPaper"}>Assets Questions</Paper>
                                            </NavLink>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <p className="heading ml-10">Risk Management</p>
                                        <div className={"adminPaperDiv"}>
                                            <NavLink exact to="/ShowListItems?page=Risks">
                                                <Paper className={"adminPaper"}>Risks</Paper>
                                            </NavLink>
                                            <NavLink exact to="/ShowListItems?page=Risk Form">
                                                <Paper className={"adminPaper"}>Risk Forms</Paper>
                                            </NavLink>
                                            <NavLink exact to="/ShowListItems?page=Risk Area">
                                                <Paper className={"adminPaper"}>Risk Areas</Paper>
                                            </NavLink>
                                            <NavLink exact to="/ShowListItems?page=Risk Category">
                                                <Paper className={"adminPaper"}>Risk Categories</Paper>
                                            </NavLink>
                                            <NavLink exact to="/ShowListItems?page=Risk Control Measure">
                                                <Paper className={"adminPaper"}>Risk Control Measures</Paper>
                                            </NavLink>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <p className="heading ml-10">Others</p>
                                        <div className={"adminPaperDiv"}>
                                            <NavLink exact to="/Suburbs">
                                                <Paper className={"adminPaper"}>Suburbs</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Status">
                                                <Paper className={"adminPaper"}>Status</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Counters">
                                                <Paper className={"adminPaper"}>Counters</Paper>
                                            </NavLink>
                                            <NavLink exact to="/FailedWorkorders">
                                                <Paper className={"adminPaper"}>Failed Workorders</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Priority">
                                                <Paper className={"adminPaper"}>Priority</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Tcp">
                                                <Paper className={"adminPaper"}>TCP</Paper>
                                            </NavLink>
                                            <NavLink exact to="/Suppliers">
                                                <Paper className={"adminPaper"}>Suppliers</Paper>
                                            </NavLink>
                                            {/* <NavLink exact to="/InventoryLocation">
                                                <Paper className={"adminPaper"}>Inventory Location</Paper>
                                            </NavLink> */}
                                            <NavLink exact to="/OnHoldReasons">
                                                <Paper className={"adminPaper"}>On Hold Reasons</Paper>
                                            </NavLink>
                                            <NavLink exact to="/IntegrationStatus">
                                                <Paper className={"adminPaper"}>Integration Status</Paper>
                                            </NavLink>
                                            <NavLink exact to="/ClearStorage">
                                                <Paper className={"adminPaper"}>Clear Storage</Paper>
                                            </NavLink>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>
        );
    }
}