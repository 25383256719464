import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import Spinner from '../../common/reusableComponents/Spinner';
import AlertDialog from '../../common/alertDialog';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
export default class AddEditFormTypes extends React.Component<any, any> {
    mandatoryFields: string[] = ['title'];
    creationSuccessMsg: string = 'Form Type been created';
    updateSuccessMsg: string = 'Form Type has been updated';


    constructor(props: any) {
        super(props);
        this.update = this.update.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.state = {
            isActive: true,
        };
    }
    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
            isActive: this.props.details.isActive && this.props.details.isActive === 'Yes' ? true : false,
        });
    }
    public async update() {
        const dataToPassToService: any = {};
        for (var i = 0; i < this.mandatoryFields.length; i++) {
            if (!this.state[this.mandatoryFields[i]] || this.state[this.mandatoryFields[i]] == "") {
                this.setState({
                    alertMessage: `Please enter ${this.mandatoryFields[i]}`,
                    showAlert: true,
                    hideAlertButton: false
                });
                return;
            }
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Title = this.state.title;
        dataToPassToService.IsActive = this.state.isActive;
        dataToPassToService.UpdatedBy = this.props.currentUser.Email;
        dataToPassToService.Updated = new Date();
        dataToPassToService.Tenantid = Config.tenantId;
        if (this.props.mode === 'edit') {
            dataToPassToService.Id = this.state.id;
            try {
                const data: any = await utility.genericUpdateAPICall(Config.FormTypesApi, this.state.id, dataToPassToService);
                if (data) {
                    this.setState({ showAlert: false });
                    this.clearFilter();
                    this.props.onClick(true);
                    this.props.message(this.updateSuccessMsg);
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    alertMessage: 'An error occoured. Please try again later.',
                    hideAlertButton: false,
                    showAlert: true
                });
            }
        } else {
            dataToPassToService.Created = new Date();
            dataToPassToService.CreatedBy = this.props.currentUser.Email;
            try {
                const data: any = await utility.genericPostAPIcall(Config.FormTypesApi, dataToPassToService);
                if (data) {
                    this.setState({ showAlert: false });
                    this.clearFilter();
                    this.props.onClick();
                    this.props.message(this.creationSuccessMsg);
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    alertMessage: 'An error occoured. Please try again later.',
                    hideAlertButton: false,
                    showAlert: true
                });
            }
        }

    }

    public clearFilter() {
        this.setState({ title: '', description: '' });
        this.props.onClose();
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Form Type`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                        <div className='popContentDiv'>
                            {!this.state.loading ?
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                                </Grid>
                                :
                                <Spinner size="large" label="Loading all component. Please wait..." />
                            }
                        </div>
                    </ScrollView>

                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.update,
                        }}
                    />
                </Popup>
            </div >
        );
    }
}

