import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';

import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditFormTypes from './AddEditFormTypes';
import moment from 'moment';

let _items: any = [];

export default class FormTypes extends React.Component<any, any> {

    public currentUser: any;
    public columns: any;
    constructor(props: any) {
        super(props);
        this.DeleteItem = this.DeleteItem.bind(this);
        this.EditItemModal = this.EditItemModal.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);

        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        };

        this.currentUser = this.props.currentUser;

        this.columns = [
            {
                field: 'id',
                title: 'ID',
                type: 'string',
                allowHiding: true,
                visibleColumn: false
            },
            {
                field: 'title',
                title: 'Title',
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'updated',
                title: 'Updated',
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            },
            {
                field: 'isActive',
                title: 'Active',
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'updatedBy',
                title: 'Updated By',
                type: 'string',
                visibleColumn: false,
                allowHiding: true
            }, {
                field: 'createdBy',
                title: 'Created By',
                type: 'string',
                visibleColumn: true,
                allowHiding: true
            },
            {
                field: 'created',
                title: 'Created',
                type: 'string',
                visibleColumn: true,
                allowHiding: true
            }
        ];
        let showHideColumns = localStorage.getItem("FormTypescolumns");
        let tempColumns;
        if (showHideColumns !== null) {
            tempColumns = JSON.parse(showHideColumns);
        } else {
            tempColumns = this.columns;
            localStorage.setItem("FormTypescolumns", JSON.stringify(this.columns));
        }

        this.state = {
            items: _items,
            columns: tempColumns
        };
    }

    public componentDidMount() {
        this._getItemData();
    }

    public DeleteItem(id: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });

        utility.genericDeleteAPICall(Config.FormTypesApi, id).then((data: any) => {
            this.setState({
                alertMessage: 'Form Type has been deleted.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData()
        }, (err) => {
            // console.log(err);
            this.setState({
                alertMessage: 'Cannot delete Form Type. Please try again later.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }
    public renderNewModal() {
        return (
            <AddEditFormTypes
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                mode={this.state.mode}
            />
        );
    }

    // add activity modal
    private _showModal = (): void => {

        this.setState({ showModal: true, details: null, mode: 'add' });
    }

    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }

    public message(message: any) {
        // //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private async _getItemData() {
        try {
            this.setState({ loading: true });
            const data: any = await utility.genericGetAPICallForList(Config.FormTypesApi, 'GET');
            const _items = data.map((r: any) => ({
                id: r.id,
                title: r.title,
                isActive: r.isActive ? 'Yes' : 'No',
                updated: r.updated ? moment(new Date(r.updated)).format("DD/MM/yyyy HH:mm a") : '',
                updatedBy: r.updatedBy,
                createdBy: r.createdBy,
                created: r.created ? moment(new Date(r.created)).format("DD/MM/yyyy HH:mm a") : ''
            }));

            this.setState({ items: _items, loading: false });
        } catch (err) {
            console.error(err);
            this.setState({ loading: false, callAfterInit: true });
        }
    }

    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public render(): React.ReactElement<any> {
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Form Types </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <DataTable
                            style={{ width: '100%' }}
                            filteralbe={true}
                            groupable={true}
                            rows={this.state.items}
                            columns={this.state.columns}
                            fileName={'FormTypes'}
                            columnChooser={'FormTypescolumns'}
                            selectionMode={false}
                            showHideColumnMenu={true}
                            callAfterInit={this.state.callAfterInit}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                            loading={this.state.loading}
                            allowExportSelectedData={false}
                            showNewModal={() => this._showModal()}
                            deleteItem={(a: any) => this.DeleteDialog(a)}
                            editItem={(a: any) => this.EditItemModal(a)}
                            // viewFile={(e:any)=>this.viewFile(e)}
                            showCommandBtn={true}
                            showNewBtn={true}
                            hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
                            showViewDetailBtn={false}
                        />
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}

