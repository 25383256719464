import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';

import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import Spinner from '../../common/reusableComponents/Spinner';
import AlertDialog from '../../common/alertDialog';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import capitalize from '@material-ui/core/utils/capitalize';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';

export default class AddEditFields extends React.Component<any, any> {
    mandatoryFields: string[] = ['title', 'caption', 'type', 'tag'];
    creationSuccessMsg: string = 'Field has been created';
    updateSuccessMsg: string = 'Field has been updated';
    fieldTypes: any = [
        { id: 'Choice', text: 'Choice' },
        { id: 'CheckBox', text: 'CheckBox' },
        { id: 'Date', text: 'Date' },
        { id: 'DateTime', text: 'DateTime' },
        { id: 'Heading', text: 'Heading' },
        { id: 'Number', text: 'Number' },
        { id: 'Photo', text: 'Photos' },
        { id: 'Radio', text: 'Radio' },
        { id: 'Text', text: 'Text' },
    ];

    constructor(props: any) {
        super(props);
        this.update = this.update.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.state = {
            builderLink: '/',
            required: false,
            isGlobal: false,
            isActive: true,
        };
    }
    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
            caption: this.props.details.caption,
            type: this.fieldTypes.filter((item: any) => item.id === this.props.details.fieldType)[0],
            choices: this.props.details.choices,
            isGlobal: this.props.details.isGlobal ? this.props.details.isGlobal : false,
            erpField: this.props.details.erpField,
            erpTable: this.props.details.erpTable,
            isActive: this.props.details.isActive && this.props.details.isActive === 'Yes' ? true : false,
            tag: this.props.details.tag,
            builderLink: '/Builder?ID=' + this.props.details.id
        });
    }
    public async update() {
        const dataToPassToService: any = {};
        for (var i = 0; i < this.mandatoryFields.length; i++) {
            if (!this.state[this.mandatoryFields[i]] || this.state[this.mandatoryFields[i]] == "") {
                this.setState({
                    alertMessage: `Please enter ${this.mandatoryFields[i]}`,
                    showAlert: true,
                    hideAlertButton: false
                });
                return;
            }
        }
        if (this.state.type && this.state.type.id === 'Choice') {
            if (!this.state.choices) {
                this.setState({
                    alertMessage: 'Please enter choices',
                    showAlert: true
                });
                return;
            } else {
                dataToPassToService.Choices = this.state.choices;
            }
        }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Title = this.state.title;
        dataToPassToService.Caption = this.state.caption;
        dataToPassToService.UpdatedBy = this.props.currentUser.Email;
        dataToPassToService.Updated = new Date();
        dataToPassToService.FieldType = this.state.type.id;
        dataToPassToService.Required = this.state.required ? this.state.required : false;
        dataToPassToService.IsGlobal = this.state.isGlobal ? this.state.isGlobal : false;
        dataToPassToService.ErpField = this.state.erpField;
        dataToPassToService.ErpTable = this.state.erpTable;
        dataToPassToService.Tag = this.state.tag;
        dataToPassToService.IsActive = this.state.isActive;
        dataToPassToService.Tenantid = Config.tenantId;
        if (this.props.mode === 'edit') {
            dataToPassToService.Id = this.state.id;
            try {
                const data: any = await utility.genericUpdateAPICall(Config.FieldsApi, this.state.id, dataToPassToService);
                if (data) {
                    this.setState({ showAlert: false });
                    this.clearFilter();
                    this.props.onClick(true);
                    this.props.message(this.updateSuccessMsg);
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    alertMessage: 'An error occoured. Please try again later.',
                    hideAlertButton: false,
                    showAlert: true
                });
            }
        } else {
            dataToPassToService.Created = new Date();
            dataToPassToService.CreatedBy = this.props.currentUser.Email;
            try {
                const data: any = await utility.genericPostAPIcall(Config.FieldsApi, dataToPassToService);
                if (data) {
                    this.setState({ showAlert: false });
                    this.clearFilter();
                    this.props.onClick();
                    this.props.message(this.creationSuccessMsg);
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    alertMessage: 'An error occoured. Please try again later.',
                    hideAlertButton: false,
                    showAlert: true
                });
            }
        }

    }

    public clearFilter() {
        this.setState({ title: '', caption: '', type: '', choices: '', required: true, erpField: '', isGlobal: false });
        this.props.onClose();
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Field`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                        <div className='popContentDiv'>
                            {!this.state.loading ?
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Caption *"
                                            value={this.state.caption}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'caption')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.fieldTypes}
                                            selectedItem={this.state.type}
                                            label='Type *'
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'type')}
                                        />
                                    </Grid>
                                    {(this.state.type && this.state.type.id === 'Choice') && (
                                        <Grid item xs={12} sm={6}>
                                            <TextAreaInput
                                                value={this.state.choices}
                                                label="Choices *"
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'choices')}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Tag"
                                            value={this.state.tag}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'tag')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Erp Field"
                                            value={this.state.erpField}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'erpField')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Erp Table"
                                            value={this.state.erpTable}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'erpTable')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <br />
                                        <CheckBoxInput value={this.state.isGlobal} onValueChanged={(e: any) => this.handleValueChange(e, 'isGlobal')} text={'Is Global'} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                                </Grid>
                                :
                                <Spinner size="large" label="Loading all component. Please wait..." />
                            }
                        </div>
                    </ScrollView>

                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.update,
                        }}
                    />
                </Popup>
            </div >
        );
    }
}

