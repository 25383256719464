export default class global {

    //////// // this is for UAT Developement 
    /////////public static homeURL: string = 'https://struatworxonline.z8.web.core.windows.net/';
    ///////public static homeURL: string = 'http://localhost:3000/';
    public static homeURL: string = 'https://colibanuat.worx.online/';
    public static spAPIURL: string = 'https://colibanuat-api.worx.online';

    public static AssetMapURL: string = 'https://cwuatworxmapstr.z8.web.core.windows.net/';
    public static mapIconFolderURL: string = 'https://struatworxonline.blob.core.windows.net/cw-uat-worxonline/Images/MapIcon';
    public static connectionString: string = "Endpoint=sb://cw-uat-worxsb.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=YJE8aO6aw/rquWAQH1NV7StXaoLS4z9Ieotw5DFakVU=";
    // for token and sing in authentication
    public static singInB2C: string = 'https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/B2C_1_SignupAndSignin'
    public static singInB2CKnownAuthority: string = 'w0rx.b2clogin.com'
    public static AzureClientAppId: string = "f515d150-7beb-4342-b36f-b7fdca883ca1";
    public static AzureTenantId: string = "777c851c-c2df-4f6c-b1ad-0ba6f8a4059e";
    public static AzureScope: string = 'https://w0rx.onmicrosoft.com/coliban-api';
    public static accessToken: string = 'uataccesstoken';
    public static isAzurefile: string = 'https://cwuatworxstr.blob.core.windows.net/';



    //---------------------------------------------------- production ----------------------------------------------------------------------------------------
    // public static isAzurefile: string = 'https://cwprodworxstr.blob.core.windows.net/';
    // public static homeURL: string = 'https://coliban.worx.online/';
    // public static spAPIURL: string = 'https://coliban-api.worx.online';

    // public static AssetMapURL: string = 'https://cwprodworxstr.z8.web.core.windows.net/';
    // public static mapIconFolderURL: string = 'https://cwprodworxstr.blob.core.windows.net/worxonline-container-prod/Images/MapIcon';

    // public static connectionString: string = "Endpoint=sb://cw-prod-worxsb.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=7sX5hSUVqJVLKMbqoNISD4uGq7UXZh8lT1OhZiwUzHY="

    // // // for token and sing in authentication
    // public static singInB2C: string = 'https://worxonlineaad.b2clogin.com/worxonlineaad.onmicrosoft.com/B2C_1_SignupAndSignin'
    // public static singInB2CKnownAuthority: string = 'worxonlineaad.b2clogin.com'
    // public static AzureClientAppId: string = "366c279e-70e4-4f7d-9a5e-88f52520a1bd";
    // public static AzureTenantId: string = "57e10ea9-4e34-4f74-aec8-e8fec13fcc28";
    // public static AzureScope: string = 'https://worxonlineaad.onmicrosoft.com/coliban-api/.default';
    // public static accessToken: string = 'prodaccesstoken';
    //--------------------------------------------------------production-end------------------------------------------------------------------------------------------

    //https://worxonlineaad.b2clogin.com/worxonlineaad.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignupAndSignin&client_id=366c279e-70e4-4f7d-9a5e-88f52520a1bd&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fcoliban.worx.online%2F&scope=openid&response_type=id_token&prompt=login



    //for service bus / Notification      


    // name of the Service Bus topic
    public static topicName: string = "workordercreated";
    // name of the subscription to the topic
    public static subscriptionName: string = "s1";

    // this is for PRODUCTION 




    // this is for GENERAL DEVELOPMENT
    //for role assignment
    public static worxOnline_Admin: string = 'Admin';
    public static worxOnline_Writer: string = 'Write';
    public static worxOnline_Contractor: string = 'Contractor';
    public static worxOnline_Reader: string = 'Read';
    public static worxOnline_WorxAdmin: string = 'WorxAdmin';
    public static worxOnline_ContractorAdmin: string = 'ContractorAdmin';

    // for scheduler status
    public static Assigned: string = 'Dispatched';
    public static Unassigned: string = 'Accepted';
    public static Reassigned: string = 'Reassigned';
    public static Complete: string = 'Complete';
    public static Closed: string = 'Closed';

    public static googleAPIKey: string = 'AIzaSyBOFrpWhKg5TX7tLlTEf-idt3HyUhY2H7U';

}