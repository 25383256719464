import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';


class ActivityDetailTemplate extends React.Component<any, any> {
    public dataSource: any;
    constructor(props: any) {
        super(props);
        let detailItems: any = props.dataSource.filter((datas: any) => datas.workorderTypeId === props.data.key.workorderTypeId)[0];

        this.state = {
            detailItems: detailItems.workorderTypeFieldRelationsView
        }
    }

    render() {
        return (
            <React.Fragment>

                <DataGrid
                    dataSource={this.state.detailItems}
                    showBorders={true}
                    columnAutoWidth={true}
                >
                    {this.props.columns && this.renderColums()}
                </DataGrid>
            </React.Fragment>
        );
    }
    public renderColums() {
        return (
            this.props.columns && this.props.columns.map((item: any) => {
                return (
                    <Column
                        dataField={item.field}
                        width={item.width}
                        caption={item.title}
                        dataType={item.type}
                        visible={item.visibleColumn}
                        visibleIndex={item.visibleIndex}
                        allowHiding={item.allowHiding}
                        format={item.type === 'date' ? "dd/MM/yyyy" : item.type === 'datetime' ? 'dd/MM/yyyy h:mm a' : ''}
                    // cellRender={this.WOLinkCell}
                    // cellRender={(e:any) => this.Cell(e, item.link)}
                    // editorOptions={{showClearButton:true}}
                    >
                    </Column>
                )
            })
        );
    }

}


export default ActivityDetailTemplate;