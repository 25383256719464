import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';
import '../../css/Workorder.css';

import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import MultiSelect from '../common/reusableComponents/MultiSelect';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import DateBoxInput from '../common/reusableComponents/DateBox';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberBoxInput from '../common/reusableComponents/NumberBox';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
let _items: any = [];
const AssetTypes = [
  { id: 'Asset', text: 'Asset' },
  { id: 'Sewer Main', text: 'Sewer Main' },
  { id: 'Water Main', text: 'Water Main' },
];
export default class Searchworkorder extends React.Component<any, any> {

  public suburbItems: any = [];
  public crewItems: any = [];
  // public employeeItems: any = [];
  public worktypeItems: any = [];
  public priorityItems: any = [];
  public criteriaval: any;
  public StatusItems: any = [];
  public WOStatusItems: any = [];
  public AssetType: any = [];
  public departmentItems: any = [];
  public teamsItems: any = [];
  public setupDataPromise: any = [];
  public columns: any;
  public myRef: any;
  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.criteriaval = '';
    this.suburbItems = [];
    this.crewItems = [];
    // this.employeeItems = [];
    this.StatusItems = [];
    this.WOStatusItems = [];
    this.worktypeItems = [];
    this.departmentItems = [];
    this.priorityItems = [];
    this.teamsItems = [];
    this.AssetType = [];
    this.setupDataPromise = [];
    this.myRef = React.createRef();
    this.getWorkOrder = this.getWorkOrder.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.viewDetials = this.viewDetials.bind(this);
    this.columns = [
      // {
      //   field: 'techoneWOnumber',
      //   title: 'IPS WO #',
      //   // width: 160,
      //   type: 'string',
      //   allowHiding: true,
      // },
      {
        field: 'Workordernumber',
        title: 'Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'workorderLink',
      },
      // {
      //   field: 'title',
      //   title: 'Title',
      //   width: 280,
      //   type: 'string',
      //   allowHiding: false,
      // },
      {
        field: 'worktype',
        title: 'Activity',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'priority',
        title: 'Priority',
        // width: 110,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'crew',
        title: 'Crew',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'department',
        title: 'Organisation',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'fieldstatus',
        title: 'Status',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'ContractorStatus',
        title: 'Contractor Status',
        // width: 120,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'servicerequest',
        title: 'Service Req #',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Salesforcecaseno',
        title: 'Saleforce #',
        width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'formType',
        title: 'Asset Type',
        // width: 180,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'location',
        title: 'Address',
        // width: 180,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'suburb',
        title: 'Suburb',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'Scope',
        title: 'Scope',
        // width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'Contractorrefno',
        title: 'Contractor WO/Ref',
        // width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'Reasontoattend',
        title: 'Reason to Attend',
        // width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },

      {
        field: 'eststartdate',
        title: 'Scheduled On',
        width: 180,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'Datereported',
        title: 'Reported On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'Datecompleted',
        title: 'Completed On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created On',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    let showHideColumns = localStorage.getItem("workorderColumns");
    let tempColumns;
    if (showHideColumns !== null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("workorderColumns", JSON.stringify(this.columns));
    }
    this.state = {
      showAllWO: false,
      items: _items,
      columns: tempColumns,
      disableTeam: true,
      disableCrew: true,
      callAfterInit: false,
      loading: false,
      AssetTypes: AssetTypes,
      allAssetTypes: AssetTypes,
      reasonToAttendsItems: [],
      scopesItems: [],
      totalResults: 100,
      expanded: 'panel1'
    };
    if (props.cacheLifecycles) {
      props.cacheLifecycles.didCache(this.componentDidCache);
      props.cacheLifecycles.didRecover(this.componentDidRecover);
    }
  }

  public componentDidCache = () => {
    ////console.log('view cached. Scroll position = ' + document.getElementById("s4-workspace").scrollTop);
    //scrollPosition = document.getElementById("s4-workspace").scrollTop;
  }

  public componentDidRecover = () => {
  }


  public componentDidMount() {
    let search = this.props.location.woProps;
    // console.log({search});
    if (search) {
      this.setState({
        department: search.department,
        crew: search.crew,
        dateCreatedfrom: search.range.startDate,
        dateCreatedTo: search.range.endDate,
      });
      setTimeout(() => this.getWorkOrder(), 500);
    }
    this.setState({ loadingFields: true });
    let tempstate = localStorage.getItem('woState');
    this.setupDataPromise = [];
    this._getsuburbDataAsync();
    this._getCrewDataAsync();
    // this._getEmployeeDataAsync();
    this._getworkTypeDataAsync();
    this._getStatusDataAsync();
    this._getAssetTypeData();
    this._getTeamDataAsync();
    this._getDepartmentData();
    this._getPrioritiesData();
    this._getScopeData();
    this._getReasonToAttendData();

    Promise.all(this.setupDataPromise).then(items => {

      if (tempstate && !search) {
        // this.state = JSON.parse(tempstate)
        let allState = JSON.parse(tempstate);
        this.setState({
          erpWOnumber: allState.workorderno ? allState.erpWOnumber : '',
          contStatus: allState.contStatus ? this.getMultiselectProps(allState.contStatus, 'StatusItems') : '',
          wostatus: allState.wostatus ? allState.wostatus : '',
          scope: allState.wostatus ? allState.scope : '',
          reasonToAttend: allState.wostatus ? allState.reasonToAttend : '',
          priority: allState.priority ? this.getMultiselectProps(allState.priority, 'priorityItems') : '',
          worktype: allState.worktype ? this.getMultiselectProps(allState.worktype, 'worktypeItems') : '',
          suburb: allState.suburb ? this.getMultiselectProps(allState.suburb, 'suburbItems') : '',
          WOLocation: allState.WOLocation ? allState.WOLocation : '',
          assetTypeItem: allState.assetTypeItem ? allState.assetTypeItem : '',
          assetId: allState.assetId ? allState.assetId : '',
          unitId1: allState.unitId1 ? allState.unitId1 : '',
          unitId2: allState.unitId2 ? allState.unitId2 : '',
          dateCreatedfrom: allState.dateCreatedfrom ? new Date(allState.dateCreatedfrom) : '',
          dateCreatedTo: allState.dateCreatedTo ? new Date(allState.dateCreatedTo) : '',
          reportfrom: allState.reportfrom ? new Date(allState.reportfrom) : '',
          reportTo: allState.reportTo ? new Date(allState.reportTo) : '',
          Completeddatefrom: allState.Completeddatefrom ? new Date(allState.Completeddatefrom) : '',
          CompleteddateTo: allState.CompleteddateTo ? new Date(allState.CompleteddateTo) : '',

          crew: allState.crew ? allState.crew : '',
          department: allState.department ? allState.department : '',
          teams: allState.teams ? allState.teams : '',
          workorderno: allState.workorderno ? allState.workorderno : '',
          serviceReqNo: allState.serviceReqNo ? allState.serviceReqNo : '',
          saleForceNo: allState.saleForceNo ? allState.saleForceNo : '',
          contractorWo: allState.contractorWo ? allState.contractorWo : '',
          expanded: allState.expanded,
          // totalRecords:allState.totalRecords ? allState.totalRecords : '',
          loadingFields: false
        })
      } else {
        this.setState({ loadingFields: false });
      }
    });


  }
  public getMultiselectProps(data: any, listData: any) {
    let returnData: Array<any> = [];
    if (this.state[listData] && data) {
      data.forEach((item: any) => {
        let tempItem = this.state[listData].find((l: any) => l.id === item.id);
        returnData.push(tempItem);
      })
    }
    return returnData;
  }

  public viewDetials(item: any) {

    this.setState({
      details: item,
      showModal: true
    });
  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getWorkOrder();
    }
  }
  public clearFilter() {
    this.setState({
      items: '',
      workorderno: '',
      erpWOnumber: '',
      wostatus: '',
      contStatus: [],
      priority: [],
      crew: '',
      department: '',
      teams: '',
      worktype: '',
      WOLocation: '',
      suburb: '',
      serviceReqNo: '',
      employee: '',
      dateCreatedfrom: '',
      dateCreatedTo: '',
      actdateCreatedfrom: '',
      actdateCreatedTo: '',
      Completeddatefrom: '',
      CompleteddateTo: '',
      reportfrom: '',
      reportTo: '',
      scope: '',
      reasonToAttend: '',
      assetTypeItem: '',
      assetId: '',
      unitId1: '',
      unitId2: '',
      saleForceNo: '',
      contractorWo: '',
      loading: false,
      totalResults: 100
      // totalRecords:''
    });

    localStorage.setItem('woState', '');
    localStorage.removeItem('datagridFilterValue');
  }

  private _getStatusDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.statuslist).then((data: any) => {
        // //console.log(data);
        this.StatusItems = [];
        this.WOStatusItems = []
        for (let r of data) {
          if (r.Statustype === 'Contractor') {
            this.StatusItems.push({ id: r.Id, text: r.Title, 'Select All': (r.Title) });
          } else if (r.Statustype === 'WorkOrder') {
            this.WOStatusItems.push({ id: r.Id, text: r.Title });
          }
        }
        this.StatusItems = utility._sortItems(this.StatusItems, 'text', false);
        this.setState({
          StatusItems: this.StatusItems,
          WOStatusItems: this.WOStatusItems,
        });
      }, (err) => {
        console.log(err);
      }));
  }
  private _getsuburbDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.suburblistname).then((data: any) => {
        //console.log(data);
        this.suburbItems = [];
        for (let r of data) {
          this.suburbItems.push({ id: r.Id, text: r.Title, 'Select All': (r.Title) });
        }
        this.suburbItems = utility._sortItems(this.suburbItems, 'text', false);
        this.setState({ suburbItems: this.suburbItems });
      }, (err) => {
        console.log(err);
      }));

  }
  private _getCrewDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.crewlistname).then((data: any) => {
        //console.log(data);
        this.crewItems = [];

        for (let r of data) {
          this.crewItems.push({ id: (r.Id), text: (r.Code + ' - ' + r.Title), departmentId: r.Departmentid, teamId: r.TeamID });
        }
        this.crewItems = utility._sortItems(this.crewItems, 'text', false);
        this.setState({ crewItems: this.crewItems });
      }, (err) => {
        console.log(err);
      }));
  }
  private _getDepartmentData() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {

        this.departmentItems = [];

        for (let r of data) {
          this.departmentItems.push({ id: r.Id, text: r.Title });
        }
        this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
        this.setState({ departmentItems: this.departmentItems });
      }, (err) => {
        console.log(err);
      }));
  }
  private _getPrioritiesData() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.PriorityList).then((data: any) => {
        //console.log(data);
        this.priorityItems = [];

        for (let r of data) {
          this.priorityItems.push({ id: (r.Title), text: `${r.Code} - ${r.Title}`, 'Select All': (r.Code + ' - ' + r.Title) });
        }
        this.setState({ priorityItems: this.priorityItems });
      }, (err) => {
        console.log(err);
      }));
  }
  private _getScopeData() {

    utility.genericGetAPICall(Config.ScopeList).then((data: any) => {
      //console.log(data);
      let scopesItems = [];

      for (let r of data) {
        scopesItems.push({ id: (r.Code), text: (r.Code + " - " + r.Description) });
      }
      this.setState({ scopesItems: scopesItems });
    }, (err) => {
      console.log(err);
    })

      ;
  }
  private _getReasonToAttendData() {

    utility.genericGetAPICall(Config.ReasoneToAttendList).then((data: any) => {
      //console.log(data);
      let reasonToAttendsItems = [];

      for (let r of data) {
        reasonToAttendsItems.push({ id: (r.Code), text: (r.Code + " - " + r.Description) });
      }
      this.setState({ reasonToAttendsItems: reasonToAttendsItems });
    }, (err) => {
      console.log(err);
    });
  }
  private _getTeamDataAsync() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.TeamList).then((data: any) => {
        //console.log(data);
        this.teamsItems = [];

        for (let r of data) {
          this.teamsItems.push({ id: r.Id, text: (r.Code + ' - ' + r.Title), departmentId: r.Departmentid });
        }
        this.teamsItems = utility._sortItems(this.teamsItems, 'text', false);
        this.setState({ teamsItems: this.teamsItems });
      }, (err) => {
        console.log(err);
      }));
  }
  private _getworkTypeDataAsync() {

    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.WTlistname).then((data: any) => {
        // console.log(data);
        this.worktypeItems = [];

        for (let r of data) {
          this.worktypeItems.push({ id: r.Id, text: (r.Title + ' - ' + r.Code + (r.WorkSystemName ? ' - ' + r.WorkSystemName : "")), 'Select All': (r.Code + ' - ' + r.Title) });
        }
        this.worktypeItems = utility._sortItems(this.worktypeItems, 'text', false);
        this.setState({ worktypeItems: this.worktypeItems });
      }, (err) => {
        console.log(err);
      }));
  }
  public _getAssetTypeData() {
    this.setupDataPromise.push(
      utility.genericGetAPICall(Config.assetType).then((data: any) => {
        this.AssetType = [];
        for (let c of data) {
          this.AssetType.push({ id: c.Id, text: c.Title });
        }
        this.AssetType = utility._sortItems(this.AssetType, 'text', false);
        this.setState({
          subAssetType: this.AssetType,
        });

      }, (err) => {
        console.log(err);
      }));
  }
  public getWorkOrder() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};
    dataToPassToService.showAll = this.state.showAllWO;
    if (this.state.workorderno) {
      dataToPassToService.WorkOrderNumber = this.state.workorderno;
      criteriaSelected = true;
    }
    if (this.state.WOLocation) {
      dataToPassToService.Location = this.state.WOLocation;
      criteriaSelected = true;
    }

    if (this.state.serviceReqNo) {
      dataToPassToService.ServiceRequestId = this.state.serviceReqNo;
      criteriaSelected = true;
    }
    if (this.state.contStatus && this.state.contStatus.length > 0) {
      let tempcontStatus: any = [];
      this.state.contStatus.forEach((element: any) => {

        tempcontStatus.push(element.id);
      });
      dataToPassToService.ContractorStatusId = tempcontStatus;
      criteriaSelected = true;
    }
    if (this.state.wostatus) {
      dataToPassToService.StatusId = [this.state.wostatus.id];
      criteriaSelected = true;
    }
    if (this.state.worktype && this.state.worktype.length > 0) {
      let tempworktype: any = [];
      this.state.worktype.forEach((element: any) => {

        tempworktype.push(element.id);
      });
      dataToPassToService.WorkTypeId = tempworktype;
      criteriaSelected = true;
    }

    if (this.state.crew) {
      dataToPassToService.CrewId = this.state.crew.id;
      criteriaSelected = true;
    }

    if (this.state.teams) {
      dataToPassToService.TeamId = this.state.teams.id;
      criteriaSelected = true;
    }

    if (this.state.department) {
      dataToPassToService.DepartmentId = this.state.department.id;
      criteriaSelected = true;
    }
    if (this.state.suburb && this.state.suburb.length > 0) {
      let tempsuburb: any = [];
      this.state.suburb.forEach((element: any) => {

        tempsuburb.push(element.text);
      });
      dataToPassToService.Suburbs = tempsuburb;
      criteriaSelected = true;
    }

    if (this.state.erpWOnumber) {
      dataToPassToService.WorkOrderNumber = this.state.erpWOnumber;
      criteriaSelected = true;
    }
    if (this.state.workSystem) {
      dataToPassToService.WorkSystem = this.state.workSystem;
      criteriaSelected = true;
    }
    if (this.state.priority && this.state.priority.length > 0) {
      let temppriority: any = [];
      this.state.priority.forEach((element: any) => {
        if ((element.text).includes('Priority')) {
          let priority = element.text.replace(" - ", "|");
          temppriority.push(priority);
        }
        else {
          temppriority.push(element.id);
        }
      });
      dataToPassToService.Priority = temppriority;
      criteriaSelected = true;
    }

    if (this.state.scope) {
      // dataToPassToService.Scope = this.state.scope.id;
      dataToPassToService.ScopeCode = this.state.scope.id;
      criteriaSelected = true;
    }

    if (this.state.reasonToAttend) {
      dataToPassToService.ReasonToAttend = this.state.reasonToAttend.id;
      criteriaSelected = true;
    }

    if (this.state.saleForceNo) {
      dataToPassToService.SalesForceCaseNo = this.state.saleForceNo;
      criteriaSelected = true;
    }

    if (this.state.contractorWo) {
      dataToPassToService.ContractorRefNo = this.state.contractorWo;
      criteriaSelected = true;
    }

    // if (this.state.assetTypeItem) {
    //   dataToPassToService.FormType = this.state.assetTypeItem.key;
    //   criteriaSelected = true;
    //   if (this.state.unitId1) {
    //     dataToPassToService.unitidtypeid = this.state.unitId1
    //   }
    //   if (this.state.unitId2) {
    //     dataToPassToService.unitid2typeid = this.state.unitId2
    //   }
    // }
    if (this.state.assetTypeItem) {
      if (!this.state.assetId && !this.state.unitId1 && !this.state.unitId2) {
        this.setState({
          alertMessage: 'Please enter AssetID/UnitID to complete the search request',
          showAlert: true,
        });
        return;
      }
      dataToPassToService.FormType = this.state.assetTypeItem.text;
      criteriaSelected = true;
      if (this.state.assetId && this.state.assetTypeItem.text === 'Asset') {
        dataToPassToService.AssetNumber = this.state.assetId
      }
      if (this.state.unitId1 && this.state.assetTypeItem.text !== 'Asset') {
        dataToPassToService.Unitidasset = this.state.unitId1
      }
      if (this.state.unitId2 && this.state.assetTypeItem.text !== 'Asset') {
        dataToPassToService.Unitid2asset = this.state.unitId2
      }
    }

    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Scheduled Start Date To should be greater than Scheduled Start Date From',
          showAlert: true
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      let createddatefrom;
      let createdTo;
      if (this.state.dateCreatedfrom) {
        createddatefrom = new Date(this.state.dateCreatedfrom);
        createddatefrom.setHours(0, 0, 0, 0);
        dataToPassToService.ScheduledStartFrom = createddatefrom.toISOString();
      }
      if (this.state.dateCreatedTo) {
        var extraday = new Date(this.state.dateCreatedTo);
        extraday.setDate(extraday.getDate() + 1);
        createdTo = extraday.toISOString().substring(0, 10) + "T13:59:59.000Z";
        dataToPassToService.ScheduledStartTo = createdTo;
      }
      criteriaSelected = true;
    }


    //completed
    if (this.state.Completeddatefrom && this.state.CompleteddateTo) {
      let dcompletedfrom = new Date(this.state.Completeddatefrom);
      let dcompletedTo = new Date(this.state.CompleteddateTo);
      if (dcompletedTo.getTime() < dcompletedfrom.getTime()) {
        this.setState({
          alertMessage: 'Completed Date To should be greater than Completed Date From',
          showAlert: true
        });
        return;
      }
    }
    if (this.state.Completeddatefrom || this.state.CompleteddateTo) {
      let dfrom;
      let dTo;
      if (this.state.Completeddatefrom) {
        dfrom = new Date(this.state.Completeddatefrom);
        dfrom.setHours(0, 0, 0, 0);
        dataToPassToService.CompletedFrom = dfrom.toISOString();
      }
      if (this.state.CompleteddateTo) {
        var extradaytocompleted = new Date(this.state.CompleteddateTo);
        extradaytocompleted.setDate(extradaytocompleted.getDate() + 1);
        dTo = extradaytocompleted.toISOString().substring(0, 10) + "T13:59:59.000Z";
        // dTo = this.state.CompleteddateTo.toISOString();
        dataToPassToService.CompletedTo = dTo;
      }
      criteriaSelected = true;
    }

    if (this.state.reportfrom && this.state.reportTo) {
      let reportfrom = new Date(this.state.reportfrom);
      let reportTo = new Date(this.state.reportTo);
      if (reportTo.getTime() < reportfrom.getTime()) {
        this.setState({
          alertMessage: 'Reported Date To should be greater than Reported Date From',
          showAlert: true
        });
        return;
      }
    }
    if (this.state.reportfrom || this.state.reportTo) {
      let reportfrom;
      let reportTo;
      if (this.state.reportfrom) {
        reportfrom = new Date(this.state.reportfrom);
        reportfrom.setHours(0, 0, 0, 0);
        dataToPassToService.DateReportedFrom = reportfrom.toISOString();
      }
      if (this.state.reportTo) {
        var extradaytoreportTodate = new Date(this.state.reportTo);
        extradaytoreportTodate.setDate(extradaytoreportTodate.getDate() + 1);
        reportTo = extradaytoreportTodate.toISOString().substring(0, 10) + "T13:59:59.000Z";
        // actualcreatedTo = this.state.actdateCreatedTo.toISOString();
        dataToPassToService.DateReportedTo = reportTo;
      }
      criteriaSelected = true;
    }
    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    this.setState({
      callAfterInit: true,
      loading: true
    });
    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 200;
    let searchWOAPi = `${Config.WOSearchAPI}?PageNumber=${pageNumber}&PageSize=${pageSize}&showAll=${this.state.showAllWO}`

    utility.genericPostAPIcall(searchWOAPi, dataToPassToService).then((data: any) => {

      _items = [];
      if (data.length === 0) {
        if (_items.length <= 0) {
          this.setState({
            alertMessage: 'No results found for this criteria',
            showAlert: true
          });
        }
        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((item: any) => {
          let serviceReqInfo = item.Servicerequestinfo ? JSON.parse(item.Servicerequestinfo) : '';

          let fulladdress;
          let suburb;
          if (item.Locationgeom && item.Locationgeom !== null) {
            let address = item.Locationgeom;
            fulladdress = address.streetNumber + ' ' + address.streetName + ' ' + address.suffix + ' ' + address.city + ' ' + address.region + ' ' + address.postalCode;
            suburb = item.Locationgeom.city;
          }

          _items.push({
            id: item.Id,
            woid: item.Id,
            servicerequest: item.Servicerequestid && item.Servicerequestid > 1 ? item.Servicerequestid : '',
            Workordernumber: item.Wonumber,
            techoneWOnumber: item.Wonumber,
            //title: item.Title,
            priority: item.Priority,
            worktype: item.Worktypename,
            crew: item.Crewtitle,
            department: item.Department,
            Salesforcecaseno: item.Salesforcecaseno,
            // latlong: item.Latlong,
            formType: item.Formtype,
            Scope: item.Scope,
            Contractorrefno: item.Contractorrefno,
            Reasontoattend: item.Reasontoattend,
            // createddate: item.Datecreated,
            Datecompleted: item.Datecompleted ? item.Datecompleted : null,
            Datereported: item.Datereported ? item.Datereported : null,
            eststartdate: item.Datescheduledstart ? item.Datescheduledstart : null,
            ContractorStatus: item.Contractorstatus,
            fieldstatus: item.Workorderstatus,
            suburb: suburb ? suburb : '',
            location: fulladdress ? fulladdress : ''
          });
        });

      }
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      _items = utility._sortItems(_items, 'updatedOn', true);
      this.setState({ items: _items, loading: false });
      var stateData = { ...this.state };
      //delet unwanted states value
      delete stateData['StatusItems'];
      delete stateData['crewItems'];
      delete stateData['departmentItems'];
      delete stateData['priorityItems'];
      delete stateData['reasonToAttendsItems'];
      delete stateData['worktypeItems'];
      delete stateData['suburbItems'];
      delete stateData['teamsItems'];
      delete stateData['scopesItems'];
      localStorage.setItem('woState', JSON.stringify(stateData));

      window.scrollTo(0, this.myRef.current.offsetTop)
      // localStorage.workorders = JSON.stringify(_items);
    }, (err: any) => {
      console.log(err);
      this.setState({
        items: _items,
        loading: false,
        alertMessage: 'Something went wrong!',
        showAlert: true
      });
    });

  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  private handleValueChange(newValue: any, fieldName: string): void {
    this.setState({ [fieldName]: newValue.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {

    if (fieldName === 'department' && e.selectedItem !== null) {
      let tempTeams = this.state.teamsItems;
      let filteredTeam = tempTeams.filter((t: any) => t.departmentId === e.selectedItem.id);
      this.setState({
        disableTeam: false,
        tempTeamItems: filteredTeam,
        disableCrew: true,
        tempCrewItems: [],
        // [fieldName]: newValue
      });
    }
    if (fieldName === 'teams' && e.selectedItem !== null) {
      let tempCrew = this.state.crewItems;
      let filteredCrew: any = [];
      tempCrew.forEach((c: any) => {
        if (c.teamId === e.selectedItem.id || c.title === "Default Crew") {
          filteredCrew.push(c);
        }
      });
      this.setState({
        disableCrew: false,
        tempCrewItems: filteredCrew,
        // [fieldName]: newValue
      });
    }
    if (fieldName === 'assetTypeItem' && e.selectedItem !== null) {
      this.setState({
        assetId: '',
        unitId1: '',
        unitId2: '',
      })
    }
    this.setState({ [fieldName]: e.selectedItem });
  }
  private _multiFieldChanged = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event });
  };
  public handleAccordionChange(item: any) {
    this.setState({ expanded: item });
  }
  public render(): React.ReactElement<any> {
    return (
      <div >
        {this.state.details && this.renderDetailsModal()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Search Work Orders </span>
          </div>
        </div>
        {!this.state.loadingFields ?
          <>
            <div className={"searchFieldDiv"}>

              <Accordion
                defaultExpanded
              //  expanded={this.state.expanded === 'panel1'} onChange={()=>this.handleAccordionChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={'classes.heading'}></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Work Order #"
                        value={this.state.erpWOnumber}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'erpWOnumber')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <MultiSelect
                        items={this.state.StatusItems}
                        onChange={(e: any) => this._multiFieldChanged(e, 'contStatus')}
                        selectedItem={this.state.contStatus}
                        label="Contractor Status"
                        stylingMode='underlined'
                        displayExpr="text"
                        showClearButton={true}
                        placeholder="Select a value..."
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.WOStatusItems}
                        selectedItem={this.state.wostatus}
                        label='WO Status'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'wostatus')}

                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.scopesItems}
                        selectedItem={this.state.scope}
                        label='Scope'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'scope')}

                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.reasonToAttendsItems}
                        selectedItem={this.state.reasonToAttend}
                        label='Reason To Attend'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'reasonToAttend')}

                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>

                      <MultiSelect
                        items={this.state.priorityItems}
                        onChange={(e: any) => this._multiFieldChanged(e, 'priority')}
                        selectedItem={this.state.priority}

                        label="Priority"
                        stylingMode='underlined'
                        displayExpr="text"
                        showClearButton={true}
                        placeholder="Select a value..."
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>

                      <MultiSelect
                        items={this.state.worktypeItems}
                        onChange={(e: any) => this._multiFieldChanged(e, 'worktype')}
                        selectedItem={this.state.worktype}
                        label="Activity"
                        stylingMode='underlined'
                        displayExpr="text"
                        showClearButton={true}
                        placeholder="Select a value..."

                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>

                      <MultiSelect
                        items={this.state.suburbItems}
                        onChange={(e: any) => this._multiFieldChanged(e, 'suburb')}
                        selectedItem={this.state.suburb}
                        label="Suburb"
                        stylingMode='underlined'
                        displayExpr="text"
                        showClearButton={true}
                        placeholder="Select a value..."
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Work Order Location"
                        value={this.state.WOLocation}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'WOLocation')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.AssetTypes}
                        selectedItem={this.state.assetTypeItem}
                        label='Asset Form Type'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'assetTypeItem')}

                      />
                    </Grid>
                    {(this.state.assetTypeItem && this.state.assetTypeItem.text === 'Asset') && (

                      <Grid item xs={12} sm={6} md={6} lg={4}>

                        <TextBoxInput
                          stylingMode='underlined'
                          label="Asset ID"
                          value={this.state.assetId}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'assetId')}
                          onKeyDown={(e: any) => this._handleKeyDown(e)}
                        />
                      </Grid>
                    )}
                    {((this.state.assetTypeItem && this.state.assetTypeItem.text === 'Sewer Main') || (this.state.assetTypeItem && this.state.assetTypeItem.text === 'Water Main')) && (
                      <>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <TextBoxInput
                            stylingMode='underlined'
                            label="Unit Id 1"
                            // pattern={"[A-Za-z]+"}
                            // minLength={2}
                            value={this.state.unitId1}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'unitId1')}
                            onKeyDown={(e: any) => this._handleKeyDown(e)}

                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <TextBoxInput
                            stylingMode='underlined'
                            label="Unit Id 2"
                            // pattern={"[A-Za-z]+"}
                            // minLength={2}
                            value={this.state.unitId2}
                            onValueChanged={(e: any) => this.handleValueChange(e, 'unitId2')}
                            onKeyDown={(e: any) => this._handleKeyDown(e)}

                          />
                        </Grid>
                      </>)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={'classes.heading'}>Search by Dates</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>

                      <DateBoxInput
                        stylingMode='underlined'
                        label={'Schedule Start Date From'}
                        value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                        type="date"
                        displayFormat={"dd/MM/yy"}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <DateBoxInput
                        stylingMode='underlined'
                        label={'Scheduled Start Date To'}
                        value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                        type="date"
                        displayFormat={"dd/MM/yy"}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <DateBoxInput
                        stylingMode='underlined'
                        label={'Completed Date From'}
                        value={this.state.Completeddatefrom ? new Date(this.state.Completeddatefrom) : null}
                        type="date"
                        displayFormat={"dd/MM/yy"}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'Completeddatefrom')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <DateBoxInput
                        stylingMode='underlined'
                        label={'Completed Date To'}
                        value={this.state.CompleteddateTo ? new Date(this.state.CompleteddateTo) : null}
                        type="date"
                        displayFormat={"dd/MM/yy"}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'CompleteddateTo')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>

                      <DateBoxInput
                        stylingMode='underlined'
                        label={'Reported Date From'}
                        value={this.state.reportfrom ? new Date(this.state.reportfrom) : null}
                        type="date"
                        displayFormat={"dd/MM/yy"}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'reportfrom')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <DateBoxInput
                        stylingMode='underlined'
                        label={'Reported Date To'}
                        value={this.state.reportTo ? new Date(this.state.reportTo) : null}
                        type="date"
                        displayFormat={"dd/MM/yy"}
                        showClearButton={true}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'reportTo')}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={'classes.heading'}> Advanced Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="WorxOnline #"
                        value={this.state.workorderno}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'workorderno')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Service Request #"
                        value={this.state.serviceReqNo}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'serviceReqNo')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}

                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Salesforce Case No."
                        value={this.state.saleForceNo}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'saleForceNo')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}

                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.departmentItems}
                        selectedItem={this.state.department}
                        label='Organisation'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.tempTeamItems}
                        disabled={this.state.disableTeam}
                        selectedItem={this.state.teams}
                        label='Teams'
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'teams')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <SelectBoxInput
                        stylingMode='underlined'
                        displayExpr="text"
                        valueExpr="id"
                        showClearButton={true}
                        items={this.state.tempCrewItems}
                        selectedItem={this.state.crew}
                        disabled={this.state.disableCrew}
                        label='Crew '
                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <TextBoxInput
                        stylingMode='underlined'
                        label="Contractor WO/Ref"
                        // pattern={"[A-Za-z]+"}
                        // minLength={2}
                        value={this.state.contractorWo}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'contractorWo')}
                        onKeyDown={(e: any) => this._handleKeyDown(e)}

                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className={"searchButtonDiv displayFlex"}>
                    <div>
                      <NumberBoxInput
                        stylingMode='underlined'
                        label="Total Results to Display"
                        min={1}
                        max={100000000}
                        value={this.state.totalResults}
                        onValueChanged={(e: any) => this.handleValueChange(e, 'totalResults')}
                      />
                    </div>
                    <div>
                      <Button onClick={this.clearFilter} variant='outlined'>
                        Clear
                      </Button>
                      <Button onClick={this.getWorkOrder} variant='outlined' color="primary" className={'button'}>
                        Search
                      </Button>
                      <CheckBoxInput value={this.state.showAllWO} onValueChanged={(e: any) => this.handleValueChange(e, 'showAllWO')} text={'Show All Work Orders'} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>

              {!this.state.loading ?
                <div ref={this.myRef}>
                  {(this.state.items && this.state.items.length > 0) && (
                    <DataTable
                      style={{ width: '100%' }}
                      filteralbe={true}
                      groupable={true}
                      rows={this.state.items}
                      columns={this.state.columns}
                      fileName={'workorders'}
                      columnChooser={'workorderColumns'}
                      selectionMode={false}
                      showHideColumnMenu={true}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      viewItem={(a: any) => this.viewDetials(a)}
                      loading={this.state.loading}
                      allowExportSelecteditems={false}
                      showNewBtn={false}
                      // showNewModal = {() => this._showModal()}
                      // deleteItem = {(a:any) => this.DeleteDialog(a)}
                      // editItem = {(a:any) => this.EditItemModal(a)}
                      showCommandBtn={false}
                      showViewDetailBtn={true}
                    />
                  )}
                </div>
                :
                // <CircularProgress className="loader" />
                <Spinner size='large' label="Loading.." className="loader" />
              }
            </div>
          </> :
          <Spinner label="Loading all fields. Please wait" size="large" />
        }

      </div>

    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public renderDetailsModal() {
    return (
      <DisplayDetailsModal
        details={this.state.details}
        columns={this.columns}
        showModal={this.state.showModal}
        onClick={() => this._closeModal()}
      />
    );
  }
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }

}

