import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import ColorBox from 'devextreme-react/color-box';
import capitalize from '@material-ui/core/utils/capitalize';


export default class AddEditCrew extends React.Component<any, any> {

    public teamsItems: any = [];
    public departmentItems: any = [];

    constructor(props: any) {
        super(props);

        this.updateCrew = this.updateCrew.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.teamsItems = [];
        this.departmentItems = [];
        this.state = {
            // prestartRequired: false
        };
    }

    public componentDidMount() {
        //console.log('component load');
        this._getDepartmentData();
        this._getTeamDataAsync();
    }
    public didMount() {
        // console.log(this.props.details);
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true,
                prestartRequired: false
            })
        }

    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            title: details.title,
            code: details.code,
            prestartRequired: this.props.details && details.prestartReq ? this.props.details.prestartReq : false,
            isActive: this.props.details.isActive,
            color: details.mapColor,
            teams: { id: details.teamID, text: details.teamName },
            hideAlertButton: false,
            mode: mode
        });
    }
    private _getTeamDataAsync() {

        utility.genericGetAPICall(Config.TeamList).then((data: any) => {
            //console.log(data);
            this.teamsItems = [];

            for (let r of data) {
                this.teamsItems.push({ id: r.Id, text: (r.Code + ' - ' + r.Title) });
            }
            this.setState({
                teamsItems: this.teamsItems,
                allteamsItems: this.teamsItems,
            });
        }, (err) => {
            console.log(err);
        });
    }

    private _getDepartmentData() {

        utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({ departmentItems: this.departmentItems });
        }, (err) => {
            console.log(err);
        });
    }

    public updateCrew() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.code) {
            this.setState({
                alertMessage: 'Please enter  code',
                showAlert: true
            });
            return;
        }
        if (!this.state.teams) {
            this.setState({
                alertMessage: 'Please select a team',
                showAlert: true
            });
            return;
        }
        // if (!this.state.color) {
        //     this.setState({
        //         alertMessage: 'Please enter color name',
        //         showAlert: true
        //     });
        //     return;
        // }

        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.Title = this.state.title;
        dataToPassToService.Code = this.state.code;
        dataToPassToService.Mapcolour = this.state.color;
        dataToPassToService.Tenantid = Config.tenantId;
        dataToPassToService.Teamid = this.state.teams.id;
        dataToPassToService.IsActive = this.state.isActive;
        dataToPassToService.Prestartrequired = this.state.prestartRequired;
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        if (this.props.mode === 'edit') {
            dataToPassToService.ID = this.state.id;
            //console.log("dataToPassToService", dataToPassToService);
            utility.genericUpdateAPICall(Config.crewlistname, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Crew has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        } else {
            dataToPassToService.Usercreated = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.crewlistname, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Crew has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        }
        utility.removeItem(Config.crewlistname);
    }

    public clearFilter() {
        this.setState({ title: '', code: '', teams: '' });
        this.props.onClose();

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }

    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }

    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Crew`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.title}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        onKeyDown={() => { }}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Code *"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.code}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                        onKeyDown={() => { }}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={this.state.teamsItems}
                                        selectedItem={this.state.teams}
                                        label='Teams *'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'teams')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {/* <TextBoxInput
                                        stylingMode='underlined'
                                        className={"TextField"}
                                        label="Color"
                                        mode="color"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.color}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'color')}
                                        required={false}
                                    /> */}
                                    <ColorBox
                                        value={this.state.color}
                                        applyValueMode="instantly"
                                        stylingMode='underlined'
                                        showClearButton={true}
                                        label="Color"
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'color')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <CheckBoxInput value={this.state.prestartRequired} onValueChanged={(e: any) => this.handleValueChange(e, 'prestartRequired')} text={'Prestart Required'} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                </Grid>
                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.updateCrew,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}

